




















































































































































































































































































































































































.box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.box img {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.red-required {
  color: red !important;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Lora', serif;
  font-size: 14px;
  font-weight: 400;
  color: #2a2a2a;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  line-height: normal;

  background: no-repeat center center fixed #071c4e;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* Firefox 28 and earlier*/
}

a {
  display: block;
  text-decoration: inherit !important;
  color: #2a2a2a;
  -webkit-transition: all 0.4s ease 0.1s;
  -moz-transition: all 0.4s ease 0.1s;
  -o-transition: all 0.4s ease 0.1s;
  transition: all 0.4s ease 0.1s;
}

a:hover {
  color: #f85c70;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.align-left-right > * {
  padding-right: 30px;
}

.align-flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.align-flex-center > * {
  padding-right: 30px;
}

.align-flex-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.align-flex-left-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.wrapper {
  width: 100%;
  padding: 0px 15px;
  margin: 0 auto;
}

@media (min-width: 1220px) {
  .wrapper {
    width: 1220px;
  }
}

.container {
  width: 100%;
  padding: 0px;
}

@media (min-width: 768px) {
  .container {
    padding: 40px 0px;
  }
}

.wrapper {
  width: 100%;
  padding: 0px 0px;
  margin: 0 auto;
}

@media (min-width: 991px) {
  .wrapper {
    width: 730px;
  }
}

.content {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  overflow: hidden;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.content-type-1 {
  /* background: url(../img/content-1-bg.png) no-repeat center center fixed #fff; */
}

.content-type-1 > * {
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.content-type-1::before {
  content: '';
  display: block;
  background: no-repeat center center #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.content-type-2 {
  /* background: url(../img/content-2-bg.png) no-repeat top center fixed #fff; */
}

.content-type-2 > * {
  /* background: url(../img/content-2-bg.png) no-repeat top center fixed #fff; */
  position: relative;
  z-index: 3;
}

.content-type-2::before {
  content: '';
  display: inline-block;
  width: 102px;
  height: 113px;
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 20px;
}

.content-type-2::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background: no-repeat center center #fff;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (min-width: 768px) {
  .content {
    padding: 60px 95px;
  }
}

h2 {
  padding-bottom: 35px;
  margin: 0px;
  font-size: 29px;
}

@media (min-width: 768px) {
  h2 {
    /*padding-bottom: 75px;*/
  }
}

.c-table {
  display: table;
  width: 100%;
  padding-bottom: 20px;
}

.c-table > li {
  display: table-row;
}

.c-table > li > div {
  display: table-cell;
  padding: 25px 0;
  border-bottom: 1px solid #eceaec;
}

.c-table > li > div.title {
  padding-right: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #002268;
  width: 200px;
}

.c-table > li > div.text {
  font-size: 15px;
  font-weight: 700;
  color: #141414;
}

.wrap-text {
  white-space: nowrap;
}

.c-table > li > div.text a {
  color: #10d083;
}

h4 {
  padding-top: 30px;
  padding-bottom: 10px;
  margin: 0px;
  font-family: 'Lora', serif;
  font-size: 14px;
  font-weight: 700;
  color: #002268;
  text-align: center;
}

h3 {
  padding-bottom: 20px;
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #002268;
  font-family: 'Lora', serif;
}

h3 span {
  white-space: nowrap;
}

@media (min-width: 768px) {
  h3 span {
    white-space: inherit;
  }
}

p {
  font-size: 14px;
  color: #595959;
  font-weight: 500;
  text-align: justify;
  /*text-align: center;*/
}

.btn {
  width: 100%;
  height: inherit;
  padding: 20px;
  border: inherit;
  font-size: 18px;
  font-weight: 700;
  background-color: #10d083;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transition: all 0.4s ease 0.1s;
  -moz-transition: all 0.4s ease 0.1s;
  -o-transition: all 0.4s ease 0.1s;
  transition: all 0.4s ease 0.1s;
}

.btn:hover {
  background-color: #494e54 !important;
  color: #fff !important;
}

@media (min-width: 768px) {
  .btn {
    padding: 20px 100px;
  }
}

.images-container {
  position: relative;
  text-align: center;
}

.images-container #closebtn {
  display: none;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 2;
  font-size: 40px;
  cursor: pointer;
}

.images-container .images-content {
  display: inline-block;
  max-width: 428px;
}

.images-container .images-content img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.images-container .big-images-content {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: scroll;
}

form {
  width: 100%;
  overflow: hidden;
}

form .c-row {
  width: 100%;
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}

form .c-row > div {
  padding: 10px 15px;
}

/* form .c-row > div label {
  width: 100%;
  display: block;
  padding-bottom: 5px;
} */
form .input-group label {
  width: 100%;
  display: block;
  font-weight: bold;
}

form .c-row > div.c-col-6 {
  width: 100%;
}

@media (min-width: 768px) {
  form .c-row > div.c-col-6 {
    width: 50%;
  }
}

form .c-row > div.c-col-12 {
  width: 100%;
}

form input,
form textarea {
  width: 100%;
  height: 40px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

form textarea {
  height: auto;
}

form .g-recaptcha > div {
  width: 100% !important;
}

form .err-text {
  color: #ff0000;
  font-size: 15px;
  font-weight: 500;
}

form .err-border {
  border-color: #a80000;
}

.form-control {
  width: 100% !important;
}

.success-label,
.fail-label {
  padding: 10px 10px 10px 30px;
  margin: 15px 0;
  border: 1px solid #d6d3d6;
  border-left: 4px solid #10d083;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.fail-label {
  border-left-color: #a80000;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 4;
  /* Sit on top */
  padding-top: 50px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
}

@media (min-width: 768px) {
  .modal-content {
    width: 60%;
  }
}

.modal-content img {
  width: 100%;
  height: auto;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.btn-err {
  width: 100%;
  padding: 20px;
  border: inherit;
  font-size: 18px;
  font-weight: 700;
  background-color: #ea383d;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transition: all 0.4s ease 0.1s;
  -moz-transition: all 0.4s ease 0.1s;
  -o-transition: all 0.4s ease 0.1s;
  transition: all 0.4s ease 0.1s;
}

.btn-err:hover {
  color: #fff;
  background-color: #f8494e;
}

.logo-content {
  text-align: center;
}

.images-box img {
  width: 100%;
  max-width: 125px;
}

.images-box svg {
  width: 100%;
  max-width: 125px;
}

.text-2 {
  padding: 30px 0;
}

.text-2 h2 {
  padding: 0px;
  margin: 0px;
  font-family: 'Lora', serif;
  font-size: 26px;
  font-weight: 700;
  color: #002472;

  @media (max-width: 375px) {
    font-size: 22px;
  }
}

.text-2 h2 span {
  display: block;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .text-2 {
    text-align: left;
  }
}

.header-nav {
  position: sticky;
  top: 0px;
  background-color: #fff;

  z-index: 99;
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #051e52;
}

.header-nav > ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.header-nav > ul > li > a {
  display: block;
  padding: 10px 15px;
  color: #000;

  @media (max-width: 375px) {
    padding: 10px;
  }
}

.header-nav > ul > li > a:hover {
  color: #10d083;
}

.header-nav > ul > li:first-child > a {
  padding-left: 0px;
}

.header-nav > ul > li:last-child > a {
  padding-right: 0px;
}
